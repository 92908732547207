<template>
  <b-row align-v="center">
    <b-col> Enable Darkmode </b-col>
    <b-col class="text-right">
      <b-form-checkbox
        v-model="$store.state.globals.isDarkMode"
        name="darkmode"
        switch
        size="sm"
      ></b-form-checkbox>
    </b-col>
  </b-row>
</template>

<script>
export default {
  methods: {
    setTheme(Class, isDarkMode) {
      this.$store.dispatch("globals/toggleDarkMode", isDarkMode);
      document.documentElement.className = Class;
    },
  },
  watch: {
    "$store.state.globals.isDarkMode": {
      handler(newVal) {
        if (newVal) {
          this.setTheme("darkmode dark", true);
        } else {
          this.setTheme("lightmode", false);
        }
      },
      immediate: true,
    },
  },
};
</script>
