var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { attrs: { "align-v": "center" } },
    [
      _c("b-col", [_vm._v(" Enable Darkmode ")]),
      _c(
        "b-col",
        { staticClass: "text-right" },
        [
          _c("b-form-checkbox", {
            attrs: { name: "darkmode", switch: "", size: "sm" },
            model: {
              value: _vm.$store.state.globals.isDarkMode,
              callback: function ($$v) {
                _vm.$set(_vm.$store.state.globals, "isDarkMode", $$v)
              },
              expression: "$store.state.globals.isDarkMode",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }